@if (items$ | async; as items) {
  @if (items && items.length > 0) {
    <h4>
      <ng-container i18n>Specifications</ng-container>
      <small><a routerLink="/users/rating" i18n>Statistics</a></small>
    </h4>
    @for (chunk of items; track chunk) {
      <div class="row">
        @for (item of chunk; track item.id) {
          <div class="col-md-6">
            <app-catalogue-list-item [item]="item"></app-catalogue-list-item>
            @if (item.contributors) {
              <p>
                @if (item.contributors.length > 1) {
                  <ng-container i18n>contributors</ng-container>
                } @else {
                  <ng-container i18n>contributor</ng-container>
                }
                @for (contributor$ of item.contributors; track contributor$) {
                  @if (contributor$ | async; as user) {
                    <app-user [user]="user"></app-user>
                  }
                }
              </p>
            }
          </div>
        }
      </div>
    }
  }
} @else {
  <div class="spinner-border" role="status"><span class="visually-hidden" i18n>Loading…</span></div>
}

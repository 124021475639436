@if (picture$ | async; as picture) {
  <div
    class="card mb-4"
    [class.vote-accept]="picture.moder_vote && picture.moder_vote.vote > 0"
    [class.vote-remove]="picture.moder_vote && picture.moder_vote.vote < 0"
    [class.vote-neutral]="picture.moder_vote && picture.moder_vote.count > 0 && picture.moder_vote.vote === 0"
  >
    @if (route && picture.thumb_medium) {
      <a [routerLink]="route">
        <img
          class="card-img-top"
          [src]="picture.thumb_medium.src"
          [alt]="picture.name_text"
          [title]="picture.name_text"
          loading="lazy"
        />
      </a>
    }
    <div class="card-body">
      <h6 class="card-title">
        @if (route) {
          <a [routerLink]="route" [innerHtml]="picture.name_html"></a>
        }
      </h6>
      <p class="picture-behaviour clearfix">
        @if (isModer$ | async) {
          @if (picture.status) {
            <span title="Status">
              @if (picture.status === 'accepted') {
                <i class="bi bi-check text-success" aria-hidden="true"></i>
              } @else if (picture.status === 'inbox') {
                <i class="bi bi-hourglass text-warning" aria-hidden="true"></i>
              } @else if (picture.status === 'removed') {
                <i class="bi bi-trash text-danger" aria-hidden="true"></i>
              } @else if (picture.status === 'removing') {
                <i class="bi bi-trash text-danger" aria-hidden="true"></i>
              }
            </span>
          }
        }
        <span i18n-title title="Resolution" class="text-nowrap me-2" ngPreserveWhitespaces>
          {{ picture.resolution }}
          @if ((isModer$ | async) && picture.cropped) {
            <i
              class="bi bi-crop"
              aria-hidden="true"
              style="cursor: help"
              i18n-title
              title="Thumbnail is cropped ({{ picture.crop_resolution }})"
            ></i>
          }
        </span>
        @if (picture.views > 0) {
          <span i18n-title title="Views" class="text-nowrap me-2" ngPreserveWhitespaces>
            <i class="bi bi-eye" aria-hidden="true"></i>
            @if (picture.views >= 1000) {
              <span>{{ picture.views / 1000 | number: '1.0-1' }}K</span>
            } @else {
              <span>{{ picture.views | number: '1.0-0' }}</span>
            }
          </span>
        }
        @if (picture.votes.positive > 0) {
          <span class="text-nowrap me-2" ngPreserveWhitespaces>
            <i class="bi bi-hand-thumbs-up-fill" aria-hidden="true"></i>
            {{ picture.votes.positive }}
          </span>
        }
        @if (picture.comments_count.total > 0) {
          <span i18n-title title="Comments" class="text-nowrap me-2">
            @if (route) {
              <a [routerLink]="route">
                @if (picture.comments_count.new > 0) {
                  {{
                    picture.comments_count.total > picture.comments_count.new
                      ? picture.comments_count.total - picture.comments_count.new
                      : ''
                  }}<span class="text-danger">+{{ picture.comments_count.new }}</span
                  ><i class="bi bi-chat-fill text-danger" aria-hidden="true"></i>
                } @else {
                  {{ picture.comments_count.total }}
                  <i class="bi bi-chat-fill" aria-hidden="true"></i>
                }
              </a>
            }
          </span>
        }
        @if (owner$ | async; as owner) {
          <br />
          <app-user [user]="owner"></app-user>
        }
      </p>
      @if (selectable) {
        <label
          class="btn btn-sm float-start me-1 mb-2"
          [class.btn-primary]="picture.selected"
          [class.btn-secondary]="!picture.selected"
          (click)="onPictureSelect(picture)"
        >
          <i class="bi bi-check" aria-hidden="true"></i>
        </label>
      }
      @if (isModer$ | async) {
        <a
          [routerLink]="['/moder/pictures', picture.id]"
          [class.float-end]="selectable"
          class="btn btn-secondary btn-sm settings mb-2"
          title="Settings"
        >
          <i class="bi bi-gear-fill" aria-hidden="true"></i>
        </a>
      }
      @if (picture.perspective_item) {
        <select
          class="form-select form-select-sm me-1 mb-2"
          [(ngModel)]="picture.perspective_item.perspective_id"
          (change)="savePerspective(picture)"
        >
          <option [ngValue]="null">--</option>
          @for (item of perspectiveOptions$ | async; track item.id) {
            <option [ngValue]="item.id">
              {{ getPerspectiveTranslation(item.name) }}
            </option>
          }
        </select>
      }
      @if (picture.similar) {
        <p class="alert alert-warning" role="alert">
          <span class="bi bi-file-earmark-image me-1" aria-hidden="true"></span>
          <a [routerLink]="['/moder/pictures', picture.similar.picture_id]" ngPreserveWhitespaces i18n
            >Similar picture: {{ picture.similar.distance }}</a
          >
        </p>
      }
    </div>
  </div>
}

@if (user) {
  <span
    class="user"
    [class.muted]="user.deleted"
    [class.long-away]="user.longAway"
    [class.green-man]="user.green"
    ngPreserveWhitespaces
  >
    <i class="bi bi-person-fill" aria-hidden="true"></i>
    @if (user.deleted) {
      <span i18n>deleted user</span>
    } @else {
      <a [routerLink]="['/users', user.identity ? user.identity : 'user' + user.id]" [textContent]="user.name"></a>
    }
  </span>
}

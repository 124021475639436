const translations: {
  [key: string]: {'add-sub-item': string; name: string; 'name-plural': string; 'new-item': string};
} = {
  1: {
    'add-sub-item': $localize`Add sub-vehicle`,
    name: $localize`Vehicle`,
    'name-plural': $localize`Vehicles`,
    'new-item': $localize`New vehicle`,
  },
  2: {
    'add-sub-item': $localize`Add sub-engine`,
    name: $localize`Engine`,
    'name-plural': $localize`Engines`,
    'new-item': $localize`New engine`,
  },
  3: {
    'add-sub-item': $localize`Add sub-category`,
    name: $localize`Category`,
    'name-plural': $localize`Categories`,
    'new-item': $localize`New category`,
  },
  4: {
    'add-sub-item': $localize`Add sub-twins`,
    name: $localize`Twins`,
    'name-plural': $localize`Twins`,
    'new-item': $localize`New twins group`,
  },
  5: {
    'add-sub-item': $localize`Add sub-brand`,
    name: $localize`Brand`,
    'name-plural': $localize`Brands`,
    'new-item': $localize`New brand`,
  },
  6: {
    'add-sub-item': $localize`Add sub-factory`,
    name: $localize`Factory`,
    'name-plural': $localize`Factories`,
    'new-item': $localize`New factory`,
  },
  7: {
    'add-sub-item': $localize`Add sub-museum`,
    name: $localize`Museum`,
    'name-plural': $localize`Museums`,
    'new-item': $localize`New museum`,
  },
  8: {
    'add-sub-item': $localize`Add sub-person`,
    name: $localize`Person`,
    'name-plural': $localize`Persons`,
    'new-item': $localize`New person`,
  },
  9: {
    'add-sub-item': $localize`Add sub copyright block`,
    name: $localize`Copyright block`,
    'name-plural': $localize`Copyright blocks`,
    'new-item': $localize`New copyright block`,
  },
};

const units: {
  [key: string]: {abbr: string; name: string};
} = {
  '1': {
    abbr: $localize`mm`,
    name: $localize`millimeter`,
  },
  '2': {
    abbr: $localize`kg`,
    name: $localize`kilogram`,
  },
  '3': {
    abbr: $localize`m`,
    name: $localize`meter`,
  },
  '4': {
    abbr: $localize`cc`,
    name: $localize`cubic centimeter`,
  },
  '5': {
    abbr: $localize`hp`,
    name: $localize`horsepower`,
  },
  '6': {
    abbr: $localize`rpm`,
    name: $localize`rotates per minute`,
  },
  '7': {
    abbr: $localize`Nm`,
    name: $localize`Newton-meter`,
  },
  '8': {
    abbr: $localize`km/h`,
    name: $localize`kilometers per hour`,
  },
  '9': {
    abbr: $localize`s`,
    name: $localize`seconds`,
  },
  '10': {
    abbr: $localize`%`,
    name: $localize`percent`,
  },
  '11': {
    abbr: $localize`°`,
    name: $localize`degree`,
  },
  '12': {
    abbr: $localize`l`,
    name: $localize`liter`,
  },
  '13': {
    abbr: $localize`l/100km`,
    name: $localize`liters per 100 kilometers`,
  },
  '14': {
    abbr: $localize`CO2 g/km`,
    name: $localize`grams of CO2 per kilometer`,
  },
  '15': {
    abbr: $localize`″`,
    name: $localize`inch`,
  },
  '16': {
    abbr: $localize`y.`,
    name: $localize`year`,
  },
  '17': {
    abbr: $localize`PS`,
    name: $localize`Pferdestärke`,
  },
  '18': {
    abbr: $localize`kW`,
    name: $localize`kilowatt`,
  },
  '19': {
    abbr: $localize`V`,
    name: $localize`volt`,
  },
  '20': {
    abbr: $localize`m³`,
    name: $localize`cubic meter`,
  },
};

const themes: {
  [key: string]: string;
} = {
  'forums/theme/all-other': $localize`All other`,
  'forums/theme/american-cars': $localize`American cars`,
  'forums/theme/british-cars': $localize`British cars`,
  'forums/theme/cars': $localize`Cars`,
  'forums/theme/chinese-cars': $localize`Chinese Cars`,
  'forums/theme/classifier': $localize`Classifier`,
  'forums/theme/french-cars': $localize`French cars`,
  'forums/theme/german-cars': $localize`German cars`,
  'forums/theme/italian-cars': $localize`Italian cars`,
  'forums/theme/japan-cars': $localize`Japan cars`,
  'forums/theme/korean-cars': $localize`Korean cars`,
  'forums/theme/moderators': $localize`Moderators`,
  'forums/theme/photos': $localize`Photo themes`,
  'forums/theme/russian-cars': $localize`Russian cars`,
  'forums/theme/this-cite': $localize`This website`,
};

const themesDescription: {
  [key: string]: string;
} = {
  'forums/theme/all-other/description': $localize`Other topics not included in the above sections`,
  'forums/theme/american-cars/description': $localize`Cadillac, Chrysler, Chevrolet, Dodge, Ford, Hummer, Pontiac, …`,
  'forums/theme/british-cars/description': $localize`Aston Martin, Bentley, MG, Rover, …`,
  'forums/theme/chinese-cars/description': $localize`Chery, Geely, Great Wall, …`,
  'forums/theme/french-cars/description': $localize`Peugeot, Renault, Citroën, Delahaye …`,
  'forums/theme/german-cars/description': $localize`Audi, BMW, Mercedes-Benz, Opel, Volkswagen, …`,
  'forums/theme/italian-cars/description': $localize`Alfa Romeo, Ferrari, Fiat, Lamborghini, Lancia, …`,
  'forums/theme/japan-cars/description': $localize`Nissan, Toyota, Honda, Mazda, …`,
  'forums/theme/korean-cars/description': $localize`Kia, Hyundai, …`,
  'forums/theme/russian-cars/description': $localize`VAZ, GAZ, UAZ, …`,
};

const prespectives: {
  [key: string]: string;
} = {
  'perspective/3/4-left': $localize`¾ left`,
  'perspective/3/4-right': $localize`¾ right`,
  'perspective/back': $localize`back`,
  'perspective/back-strict': $localize`back (strict)`,
  'perspective/boot': $localize`boot`,
  'perspective/bottom': $localize`bottom`,
  'perspective/chassis': $localize`chassis`,
  'perspective/cutaway': $localize`cutaway`,
  'perspective/dashboard': $localize`dashboard`,
  'perspective/exterior-details': $localize`exterior details`,
  'perspective/front': $localize`front`,
  'perspective/front-panel': $localize`front panel`,
  'perspective/front-strict': $localize`front (strict)`,
  'perspective/interior': $localize`interior`,
  'perspective/label': $localize`label`,
  'perspective/left': $localize`left`,
  'perspective/left-strict': $localize`left (strict)`,
  'perspective/logo': $localize`logo`,
  'perspective/mascot': $localize`mascot`,
  'perspective/mixed': $localize`mixed`,
  'perspective/mockup': $localize`mockup`,
  'perspective/n/a': $localize`n/a`,
  'perspective/right': $localize`right`,
  'perspective/right-strict': $localize`right (strict)`,
  'perspective/sketch': $localize`sketch`,
  'perspective/under-the-hood': $localize`under the hood`,
  'perspective/upper': $localize`upper`,
  'perspective/upper-strict': $localize`upper (strict)`,
};

const vehicleTypes: {
  [key: string]: string;
} = {
  'car-type/2-floor-bus': $localize`2-floor bus`,
  'car-type/2door-hardtop': $localize`2-door hardtop`,
  'car-type/4door-hardtop': $localize`4-door hardtop`,
  'car-type/barchetta': $localize`Barchetta`,
  'car-type/brougham': $localize`Brougham`,
  'car-type/bus': $localize`Bus`,
  'car-type/cabrio-coupe': $localize`Cabrio-coupe`,
  'car-type/cabriolet': $localize`Cabriolet`,
  'car-type/car': $localize`Car`,
  'car-type/caravan': $localize`Light van`,
  'car-type/coupe': $localize`Coupe`,
  'car-type/crossover': $localize`Crossover`,
  'car-type/fastback-coupe': $localize`Fastback-coupe`,
  'car-type/fastback-sedan': $localize`Fastback-sedan`,
  'car-type/hatchback': $localize`Hatchback`,
  'car-type/landau': $localize`Landau`,
  'car-type/liftback-coupe': $localize`Liftback-coupe`,
  'car-type/liftback-sedan': $localize`Liftback-sedan`,
  'car-type/limousine': $localize`Limousine`,
  'car-type/minibus': $localize`Minibus`,
  'car-type/minivan': $localize`Minivan`,
  'car-type/moto': $localize`Moto`,
  'car-type/multiplex-bus': $localize`Multisection bus`,
  'car-type/offroad': $localize`SUV`,
  'car-type/offroad-cabrio': $localize`SUV-cabrio`,
  'car-type/offroad-short': $localize`Short-SUV`,
  'car-type/phaeton': $localize`Phaeton`,
  'car-type/pickup': $localize`Pickup`,
  'car-type/roadster': $localize`Roadster`,
  'car-type/sedan': $localize`Sedan`,
  'car-type/spyder': $localize`Spyder`,
  'car-type/targa': $localize`Targa`,
  'car-type/tonneau': $localize`Tonneau`,
  'car-type/town-car': $localize`Town car`,
  'car-type/tracked': $localize`Tracked platform`,
  'car-type/tractor': $localize`Tractor`,
  'car-type/truck': $localize`Truck`,
  'car-type/universal': $localize`Touring`,
  'car-type/van': $localize`Van`,
};

const vehicleTypesRp: {
  [key: string]: string;
} = {
  'car-type-rp/2-floor-bus': $localize`:Vehicle type in context of "most fastest among Roadsters"@@car-type-rp/2-floor-bus:2-floor buses`,
  'car-type-rp/2door-hardtop': $localize`:Vehicle type in context of "most fastest among Roadsters"@@car-type-rp/2door-hardtop:2-door hardtops`,
  'car-type-rp/4door-hardtop': $localize`:Vehicle type in context of "most fastest among Roadsters"@@car-type-rp/4door-hardtop:4-door hardtops`,
  'car-type-rp/barchetta': $localize`:Vehicle type in context of "most fastest among Roadsters"@@car-type-rp/barchetta:Barchettas`,
  'car-type-rp/brougham': $localize`:Vehicle type in context of "most fastest among Roadsters"@@car-type-rp/brougham:Broughams`,
  'car-type-rp/bus': $localize`:Vehicle type in context of "most fastest among Roadsters"@@car-type-rp/bus:Buses`,
  'car-type-rp/cabrio-coupe': $localize`:Vehicle type in context of "most fastest among Roadsters"@@car-type-rp/cabrio-coupe:Cabrio-coupes`,
  'car-type-rp/cabriolet': $localize`:Vehicle type in context of "most fastest among Roadsters"@@car-type-rp/cabriolet:Cabriolets`,
  'car-type-rp/car': $localize`:Vehicle type in context of "most fastest among Roadsters"@@car-type-rp/car:Cars`,
  'car-type-rp/caravan': $localize`:Vehicle type in context of "most fastest among Roadsters"@@car-type-rp/caravan:Light vans`,
  'car-type-rp/coupe': $localize`:Vehicle type in context of "most fastest among Roadsters"@@car-type-rp/coupe:Coupes`,
  'car-type-rp/crossover': $localize`:Vehicle type in context of "most fastest among Roadsters"@@car-type-rp/crossover:Crossovers`,
  'car-type-rp/fastback-coupe': $localize`:Vehicle type in context of "most fastest among Roadsters"@@car-type-rp/fastback-coupe:Fastback-coupes`,
  'car-type-rp/fastback-sedan': $localize`:Vehicle type in context of "most fastest among Roadsters"@@car-type-rp/fastback-sedan:Fastback-sedans`,
  'car-type-rp/hatchback': $localize`:Vehicle type in context of "most fastest among Roadsters"@@car-type-rp/hatchback:Hatchbacks`,
  'car-type-rp/landau': $localize`:Vehicle type in context of "most fastest among Roadsters"@@car-type-rp/landau:Landaus`,
  'car-type-rp/liftback-coupe': $localize`:Vehicle type in context of "most fastest among Roadsters"@@car-type-rp/liftback-coupe:Liftback-coupes`,
  'car-type-rp/liftback-sedan': $localize`:Vehicle type in context of "most fastest among Roadsters"@@car-type-rp/liftback-sedan:Liftback-sedans`,
  'car-type-rp/limousine': $localize`:Vehicle type in context of "most fastest among Roadsters"@@car-type-rp/limousine:Limousines`,
  'car-type-rp/minibus': $localize`:Vehicle type in context of "most fastest among Roadsters"@@car-type-rp/minibus:Minibuses`,
  'car-type-rp/minivan': $localize`:Vehicle type in context of "most fastest among Roadsters"@@car-type-rp/minivan:Minivans`,
  'car-type-rp/moto': $localize`:Vehicle type in context of "most fastest among Roadsters"@@car-type-rp/moto:Motos`,
  'car-type-rp/multiplex-bus': $localize`:Vehicle type in context of "most fastest among Roadsters"@@car-type-rp/multiplex-bus:Multisection buses`,
  'car-type-rp/offroad': $localize`:Vehicle type in context of "most fastest among Roadsters"@@car-type-rp/offroad:SUVs`,
  'car-type-rp/offroad-cabrio': $localize`:Vehicle type in context of "most fastest among Roadsters"@@car-type-rp/offroad-cabrio:SUV-cabrios`,
  'car-type-rp/offroad-short': $localize`:Vehicle type in context of "most fastest among Roadsters"@@car-type-rp/offroad-short:Short-SUVs`,
  'car-type-rp/phaeton': $localize`:Vehicle type in context of "most fastest among Roadsters"@@car-type-rp/phaeton:Phaetons`,
  'car-type-rp/pickup': $localize`:Vehicle type in context of "most fastest among Roadsters"@@car-type-rp/pickup:Pickups`,
  'car-type-rp/roadster': $localize`:Vehicle type in context of "most fastest among Roadsters"@@car-type-rp/roadster:Roadsters`,
  'car-type-rp/sedan': $localize`:Vehicle type in context of "most fastest among Roadsters"@@car-type-rp/sedan:Sedans`,
  'car-type-rp/spyder': $localize`:Vehicle type in context of "most fastest among Roadsters"@@car-type-rp/spyder:Spyders`,
  'car-type-rp/targa': $localize`:Vehicle type in context of "most fastest among Roadsters"@@car-type-rp/targa:Targa`,
  'car-type-rp/tonneau': $localize`:Vehicle type in context of "most fastest among Roadsters"@@car-type-rp/tonneau:Tonneaus`,
  'car-type-rp/town-car': $localize`:Vehicle type in context of "most fastest among Roadsters"@@car-type-rp/town-car:Town cars`,
  'car-type-rp/tracked': $localize`:Vehicle type in context of "most fastest among Roadsters"@@car-type-rp/tracked:Tracked platforms`,
  'car-type-rp/tractor': $localize`:Vehicle type in context of "most fastest among Roadsters"@@car-type-rp/tractor:Tractors`,
  'car-type-rp/truck': $localize`:Vehicle type in context of "most fastest among Roadsters"@@car-type-rp/truck:Trucks`,
  'car-type-rp/universal': $localize`:Vehicle type in context of "most fastest among Roadsters"@@car-type-rp/universal:Tourings`,
  'car-type-rp/van': $localize`:Vehicle type in context of "most fastest among Roadsters"@@car-type-rp/van:Vans`,
};

const attrs: {
  [key: string]: string;
} = {
  'specs/attrs/14': $localize`geometry`,
  'specs/attrs/14/4': $localize`wheel base`,
  'specs/attrs/14/17': $localize`dimensions`,
  'specs/attrs/14/17/1': $localize`length`,
  'specs/attrs/14/17/2': $localize`width`,
  'specs/attrs/14/17/3': $localize`height`,
  'specs/attrs/14/17/140': $localize`width, including mirrors`,
  'specs/attrs/14/17/141': $localize`height, including railings`,
  'specs/attrs/14/17/203': $localize`height with open roof`,
  'specs/attrs/14/18': $localize`track`,
  'specs/attrs/14/18/5': $localize`front`,
  'specs/attrs/14/18/6': $localize`rear`,
  'specs/attrs/14/63': $localize`air resistance`,
  'specs/attrs/14/63/64': $localize`frontal`,
  'specs/attrs/14/63/65': $localize`lateral`,
  'specs/attrs/14/167': $localize`ground clearance`,
  'specs/attrs/14/167/7': $localize`standard`,
  'specs/attrs/14/167/168': $localize`max`,
  'specs/attrs/14/167/176': $localize`min`,
  'specs/attrs/15': $localize`suspension and steering`,
  'specs/attrs/15/8': $localize`front suspension type (deprecated)`,
  'specs/attrs/15/9': $localize`rear suspension type (deprecated)`,
  'specs/attrs/15/10': $localize`steering type`,
  'specs/attrs/15/208': $localize`front suspension`,
  'specs/attrs/15/208/209': $localize`elastic element type`,
  'specs/attrs/15/208/210': $localize`type guide vanes`,
  'specs/attrs/15/208/211': $localize`shock absorbers`,
  'specs/attrs/15/208/211/213': $localize`availability`,
  'specs/attrs/15/208/211/214': $localize`nature of the action`,
  'specs/attrs/15/208/211/215': $localize`operating principle`,
  'specs/attrs/15/208/211/216': $localize`adjustability`,
  'specs/attrs/15/208/212': $localize`anti-roll bar`,
  'specs/attrs/15/217': $localize`rear suspension`,
  'specs/attrs/15/217/218': $localize`elastic element type`,
  'specs/attrs/15/217/219': $localize`type guide vanes`,
  'specs/attrs/15/217/220': $localize`shock absorbers`,
  'specs/attrs/15/217/220/222': $localize`availability`,
  'specs/attrs/15/217/220/223': $localize`nature of the action`,
  'specs/attrs/15/217/220/224': $localize`operating principle`,
  'specs/attrs/15/217/220/225': $localize`adjustability`,
  'specs/attrs/15/217/221': $localize`anti-roll bar`,
  'specs/attrs/16': $localize`basic`,
  'specs/attrs/16/12': $localize`number of places`,
  'specs/attrs/16/12/67': $localize`total`,
  'specs/attrs/16/12/68': $localize`are defective`,
  'specs/attrs/16/12/69': $localize`standing`,
  'specs/attrs/16/12/103': $localize`total capacity`,
  'specs/attrs/16/13': $localize`doors counts`,
  'specs/attrs/16/66': $localize`steering wheel location`,
  'specs/attrs/16/204': $localize`design`,
  'specs/attrs/22': $localize`engine`,
  'specs/attrs/22/19': $localize`location`,
  'specs/attrs/22/19/20': $localize`location`,
  'specs/attrs/22/19/21': $localize`orientation`,
  'specs/attrs/22/23': $localize`supply system`,
  'specs/attrs/22/24': $localize`cylinders/valves`,
  'specs/attrs/22/24/25': $localize`number of cylinders`,
  'specs/attrs/22/24/26': $localize`cylinder arrangement`,
  'specs/attrs/22/24/27': $localize`valves per cylinder`,
  'specs/attrs/22/24/28': $localize`cylinder diameter`,
  'specs/attrs/22/24/29': $localize`stroke`,
  'specs/attrs/22/24/159': $localize`camber`,
  'specs/attrs/22/30': $localize`compression ratio`,
  'specs/attrs/22/31': $localize`volume`,
  'specs/attrs/22/32': $localize`power`,
  'specs/attrs/22/32/33': $localize`power`,
  'specs/attrs/22/32/34': $localize`in the range from`,
  'specs/attrs/22/32/35': $localize`in the range to`,
  'specs/attrs/22/32/171': $localize`max power (DIN)`,
  'specs/attrs/22/32/172': $localize`max power (SAE certified)`,
  'specs/attrs/22/32/173': $localize`max power (SAE net)`,
  'specs/attrs/22/32/174': $localize`max power (SAE gross)`,
  'specs/attrs/22/32/177': $localize`max power (JIS D 1001)`,
  'specs/attrs/22/32/178': $localize`max power (GOST)`,
  'specs/attrs/22/36': $localize`torque`,
  'specs/attrs/22/36/37': $localize`torque`,
  'specs/attrs/22/36/38': $localize`in the range from`,
  'specs/attrs/22/36/39': $localize`in the range to`,
  'specs/attrs/22/98': $localize`fuel`,
  'specs/attrs/22/99': $localize`turbo`,
  'specs/attrs/22/100': $localize`name`,
  'specs/attrs/22/156': $localize`block material`,
  'specs/attrs/22/179': $localize`cooling`,
  'specs/attrs/22/206': $localize`Gas distribution mechanism`,
  'specs/attrs/22/207': $localize`type`,
  'specs/attrs/40': $localize`transmission`,
  'specs/attrs/40/41': $localize`drive unit`,
  'specs/attrs/40/42': $localize`gearbox`,
  'specs/attrs/40/42/43': $localize`type`,
  'specs/attrs/40/42/44': $localize`number of gears`,
  'specs/attrs/40/42/139': $localize`name`,
  'specs/attrs/40/83': $localize`clutch`,
  'specs/attrs/45': $localize`Modification name`,
  'specs/attrs/46': $localize`dynamic properties`,
  'specs/attrs/46/47': $localize`max speed`,
  'specs/attrs/46/48': $localize`acceleration to 100 km/h`,
  'specs/attrs/46/49': $localize`acceleration to 200 km/h`,
  'specs/attrs/46/50': $localize`acceleration to 300 km/h`,
  'specs/attrs/46/51': $localize`400m run time`,
  'specs/attrs/46/52': $localize`1000m run time`,
  'specs/attrs/46/53': $localize`speed limiter`,
  'specs/attrs/46/160': $localize`braking time at 100 km/h`,
  'specs/attrs/46/161': $localize`stopping distance from 100 km/h`,
  'specs/attrs/46/175': $localize`acceleration to 60 mph`,
  'specs/attrs/46/180': $localize`acceleration to 60 km/h`,
  'specs/attrs/54': $localize`other specs`,
  'specs/attrs/54/55': $localize`approach angle`,
  'specs/attrs/54/56': $localize`departure angle`,
  'specs/attrs/54/57': $localize`fuel tank capacity`,
  'specs/attrs/54/57/58': $localize`main`,
  'specs/attrs/54/57/59': $localize`additional`,
  'specs/attrs/54/60': $localize`boot volume`,
  'specs/attrs/54/60/61': $localize`min`,
  'specs/attrs/54/60/62': $localize`max`,
  'specs/attrs/54/78': $localize`fuel consumption`,
  'specs/attrs/54/78/183': $localize`Unknown method`,
  'specs/attrs/54/78/183/79': $localize`city`,
  'specs/attrs/54/78/183/80': $localize`highway`,
  'specs/attrs/54/78/183/81': $localize`mixed`,
  'specs/attrs/54/78/184': $localize`ECE`,
  'specs/attrs/54/78/184/185': $localize`90 km/h`,
  'specs/attrs/54/78/184/186': $localize`120 km/h`,
  'specs/attrs/54/78/184/187': $localize`city`,
  'specs/attrs/54/78/184/188': $localize`combined`,
  'specs/attrs/54/78/189': $localize`EPA (to 2008)`,
  'specs/attrs/54/78/189/190': $localize`city`,
  'specs/attrs/54/78/189/191': $localize`highway`,
  'specs/attrs/54/78/192': $localize`EPA (from 2008)`,
  'specs/attrs/54/78/192/193': $localize`city`,
  'specs/attrs/54/78/192/194': $localize`highway`,
  'specs/attrs/54/78/199': $localize`EU 93/116/EC`,
  'specs/attrs/54/78/199/200': $localize`urban`,
  'specs/attrs/54/78/199/201': $localize`extra urban`,
  'specs/attrs/54/78/199/202': $localize`combined`,
  'specs/attrs/54/138': $localize`\"start-stop\" system`,
  'specs/attrs/54/158': $localize`capacity`,
  'specs/attrs/54/195': $localize`spread`,
  'specs/attrs/54/195/11': $localize`diameter`,
  'specs/attrs/54/195/196': $localize`wall-to-wall`,
  'specs/attrs/54/195/197': $localize`curb-to-curb`,
  'specs/attrs/54/198': $localize`turns of steering wheel`,
  'specs/attrs/54/205': $localize`towing weight`,
  'specs/attrs/54/226': $localize`body volume`,
  'specs/attrs/70': $localize`weight`,
  'specs/attrs/70/71': $localize`dry`,
  'specs/attrs/70/72': $localize`curb`,
  'specs/attrs/70/73': $localize`full`,
  'specs/attrs/74': $localize`brake system`,
  'specs/attrs/74/77': $localize`ABS`,
  'specs/attrs/74/142': $localize`front brakes`,
  'specs/attrs/74/142/75': $localize`description`,
  'specs/attrs/74/142/144': $localize`type`,
  'specs/attrs/74/142/146': $localize`diameter`,
  'specs/attrs/74/142/148': $localize`thickness`,
  'specs/attrs/74/142/150': $localize`material`,
  'specs/attrs/74/142/152': $localize`ventilated`,
  'specs/attrs/74/142/153': $localize`perforated`,
  'specs/attrs/74/143': $localize`rear brakes`,
  'specs/attrs/74/143/76': $localize`description`,
  'specs/attrs/74/143/145': $localize`type`,
  'specs/attrs/74/143/147': $localize`diameter`,
  'specs/attrs/74/143/149': $localize`thickness`,
  'specs/attrs/74/143/151': $localize`material`,
  'specs/attrs/74/143/154': $localize`ventilated`,
  'specs/attrs/74/143/155': $localize`perforated`,
  'specs/attrs/82': $localize`emissions`,
  'specs/attrs/84': $localize`wheels and tyres`,
  'specs/attrs/84/85': $localize`front`,
  'specs/attrs/84/85/87': $localize`tyre width`,
  'specs/attrs/84/85/88': $localize`diameter`,
  'specs/attrs/84/85/89': $localize`rim width`,
  'specs/attrs/84/85/90': $localize`tyre height`,
  'specs/attrs/84/85/162': $localize`rim offset (ET)`,
  'specs/attrs/84/86': $localize`rear`,
  'specs/attrs/84/86/91': $localize`tyre width`,
  'specs/attrs/84/86/92': $localize`diameter`,
  'specs/attrs/84/86/93': $localize`rim width`,
  'specs/attrs/84/86/94': $localize`tyre height`,
  'specs/attrs/84/86/163': $localize`rim offset (ET)`,
  'specs/attrs/84/164': $localize`rims model`,
  'specs/attrs/84/165': $localize`rims material`,
  'specs/attrs/95': $localize`Production years`,
  'specs/attrs/95/96': $localize`с`,
  'specs/attrs/95/97': $localize`по`,
  'specs/attrs/95/104': $localize`модельные`,
  'specs/attrs/95/104/113': $localize`с`,
  'specs/attrs/95/104/114': $localize`по`,
  'specs/attrs/95/106': $localize`Sales`,
  'specs/attrs/95/106/109': $localize`с`,
  'specs/attrs/95/106/109/129': $localize`год`,
  'specs/attrs/95/106/109/130': $localize`месяц`,
  'specs/attrs/95/106/109/131': $localize`число`,
  'specs/attrs/95/106/111': $localize`по`,
  'specs/attrs/95/106/111/132': $localize`год`,
  'specs/attrs/95/106/111/133': $localize`месяц`,
  'specs/attrs/95/106/111/134': $localize`число`,
  'specs/attrs/95/107': $localize`дебют на автосалоне`,
  'specs/attrs/95/107/118': $localize`год`,
  'specs/attrs/95/107/119': $localize`месяц`,
  'specs/attrs/95/107/120': $localize`число`,
  'specs/attrs/95/108': $localize`производство`,
  'specs/attrs/95/108/121': $localize`с`,
  'specs/attrs/95/108/121/123': $localize`год`,
  'specs/attrs/95/108/121/124': $localize`месяц`,
  'specs/attrs/95/108/121/125': $localize`число`,
  'specs/attrs/95/108/122': $localize`по`,
  'specs/attrs/95/108/122/126': $localize`год`,
  'specs/attrs/95/108/122/127': $localize`месяц`,
  'specs/attrs/95/108/122/128': $localize`число`,
  'specs/attrs/95/135': $localize`участие в соревнованиях`,
  'specs/attrs/95/135/136': $localize`с`,
  'specs/attrs/95/135/137': $localize`по`,
  'specs/attrs/157': $localize`emission standard`,
  'specs/attrs/170': $localize`production place`,
  'specs/attrs/181': $localize`electric`,
  'specs/attrs/181/182': $localize`onboard voltage`,
  'specs/attrs/dynamic/max-reverse-speed': $localize`Max speed (reverse)`,
  'specs/attrs/transmission/gearbox/reverse-gears': $localize`Number of reverse gears`,
  'specs/attrs/wheels/auto-pumping-tires': $localize`Auto pumping tires`,
};

const attrDescriptions: {
  [key: string]: string;
} = {
  'specs/attrs/14/17/description': $localize`external dimensions`,
  'specs/attrs/14/167/description': '',
  'specs/attrs/16/12/67/description': $localize`seating`,
  'specs/attrs/16/12/103/description': $localize`including standing`,
  'specs/attrs/22/32/33/description': $localize`Metric (PS)\n1 HP = 1.014 metric PS or CV`,
  'specs/attrs/22/32/171/description': $localize`Power by DIN 70020. European standard`,
  'specs/attrs/22/32/172/description': $localize`SAE Certified Power. US standard 2005-06`,
  'specs/attrs/22/32/173/description': $localize`US standard 1971-72.\nTransmission is not considered. Attachments - considered`,
  'specs/attrs/22/32/174/description': $localize`US standard 1972 year, roughly corresponding to the power measurement technology to 1972.\nTransmission is not considered`,
  'specs/attrs/22/32/178/description': $localize`Standard USSR and Russia`,
  'specs/attrs/54/78/199/202/description': $localize`36.8% urban + 63.2% extra urban`,
  'specs/attrs/54/195/11/description': $localize`axis. 2×radius`,
  'specs/attrs/54/195/196/description': '',
  'specs/attrs/54/195/197/description': '',
};

const attrListOptions: {
  [key: string]: string;
} = {
  'specs/attrs/15/208/209/options/108': $localize`coil spring`,
  'specs/attrs/15/208/209/options/109': $localize`leaf spring`,
  'specs/attrs/15/208/209/options/110': $localize`pneumatic`,
  'specs/attrs/15/208/209/options/111': $localize`hydropneumatic`,
  'specs/attrs/15/208/209/options/112': $localize`torsion`,
  'specs/attrs/15/208/209/options/113': $localize`rubber-elastic elements`,
  'specs/attrs/15/208/209/options/114': $localize`coil spring with push rod`,
  'specs/attrs/15/208/209/options/115': $localize`coil spring with pull rod`,
  'specs/attrs/15/208/209/options/116': $localize`leaf spring on the transverse springs`,
  'specs/attrs/15/208/209/options/117': $localize`leaf spring on longitudinal leaf springs`,
  'specs/attrs/15/208/209/options/118': $localize`on the longitudinal elliptic leaf springs`,
  'specs/attrs/15/208/209/options/119': $localize`on longitudinal ¾-elliptic leaf springs`,
  'specs/attrs/15/208/209/options/120': $localize`on the longitudinal ¼-elliptic leaf springs`,
  'specs/attrs/15/208/209/options/121': $localize`on longitudinal semi-elliptic leaf springs`,
  'specs/attrs/15/208/209/options/122': $localize`on longitudinal cantilever leaf springs`,
  'specs/attrs/15/208/209/options/123': $localize`on the longitudinal balancing leaf springs`,
  'specs/attrs/15/208/209/options/124': $localize`torsion bar with the push rod`,
  'specs/attrs/15/208/209/options/125': $localize`torsion bar with the pull rod`,
  'specs/attrs/15/208/209/options/178': $localize`none`,
  'specs/attrs/15/208/210/options/126': $localize`dependent`,
  'specs/attrs/15/208/210/options/127': $localize`independent`,
  'specs/attrs/15/208/210/options/128': $localize`semidependent`,
  'specs/attrs/15/208/210/options/129': $localize`dependent linkage-less`,
  'specs/attrs/15/208/210/options/130': $localize`dependent to the trailing arms`,
  'specs/attrs/15/208/210/options/131': $localize`dependent to the hard pipe or drawbar (Torque-tube or Torque-Rod)`,
  'specs/attrs/15/208/210/options/132': $localize`independent with swinging half-axles`,
  'specs/attrs/15/208/210/options/133': $localize`independent trailing arm`,
  'specs/attrs/15/208/210/options/134': $localize`independent suspension Dubonnet`,
  'specs/attrs/15/208/210/options/135': $localize`independent on oblique levers`,
  'specs/attrs/15/208/210/options/136': $localize`independent double wishbone`,
  'specs/attrs/15/208/210/options/137': $localize`independent multi-link`,
  'specs/attrs/15/208/210/options/138': $localize`independent candle`,
  'specs/attrs/15/208/210/options/139': $localize`semidependent De Dion`,
  'specs/attrs/15/208/210/options/140': $localize`semidependent torsion-lever`,
  'specs/attrs/15/208/210/options/141': $localize`dependent with trailing arms with Watt's mechanism`,
  'specs/attrs/15/208/210/options/142': $localize`dependent with trailing arms with Scott-Russell mechanism`,
  'specs/attrs/15/208/210/options/143': $localize`dependent with trailing arms with a Panhard rod`,
  'specs/attrs/15/208/210/options/144': $localize`dependent with hard pipe or drawbar (Torque-tube or Torque-Rod) with Watt's mechanism`,
  'specs/attrs/15/208/210/options/145': $localize`dependent with hard pipe or drawbar (Torque-tube or Torque-Rod) with Scott-Russell mechanism`,
  'specs/attrs/15/208/210/options/146': $localize`dependent with hard pipe or drawbar (Torque-tube or Torque-Rod) with a Panhard rod`,
  'specs/attrs/15/208/210/options/147': $localize`independent candle sweep (McPherson)`,
  'specs/attrs/15/208/210/options/148': $localize`independent candle sweep (McPherson) on longitudinal and transverse arms`,
  'specs/attrs/15/208/210/options/149': $localize`semidependent torsion-lever with Watt's mechanism`,
  'specs/attrs/15/208/210/options/150': $localize`semidependent torsion-lever with Scott-Russell mechanism`,
  'specs/attrs/15/208/210/options/151': $localize`semidependent torsion-lever a Panhard rod`,
  'specs/attrs/15/208/211/214/options/152': $localize`unilateral`,
  'specs/attrs/15/208/211/214/options/153': $localize`double-sided`,
  'specs/attrs/15/208/211/215/options/154': $localize`friction (mechanical)`,
  'specs/attrs/15/208/211/215/options/155': $localize`hydraulic`,
  'specs/attrs/15/208/211/215/options/156': $localize`hydraulic arm`,
  'specs/attrs/15/208/211/215/options/157': $localize`hydraulic twin-tube`,
  'specs/attrs/15/208/211/215/options/158': $localize`hydraulic single tube`,
  'specs/attrs/15/208/211/215/options/159': $localize`oleo pneumatic`,
  'specs/attrs/15/217/218/options/160': $localize`coil spring`,
  'specs/attrs/15/217/218/options/161': $localize`coil spring with push rod`,
  'specs/attrs/15/217/218/options/162': $localize`coil spring with pull rod`,
  'specs/attrs/15/217/218/options/163': $localize`leaf spring`,
  'specs/attrs/15/217/218/options/164': $localize`leaf spring on the transverse springs`,
  'specs/attrs/15/217/218/options/165': $localize`leaf spring on longitudinal leaf springs`,
  'specs/attrs/15/217/218/options/166': $localize`on the longitudinal elliptic leaf springs`,
  'specs/attrs/15/217/218/options/167': $localize`on longitudinal ¾-elliptic leaf springs`,
  'specs/attrs/15/217/218/options/168': $localize`on the longitudinal ¼-elliptic leaf springs`,
  'specs/attrs/15/217/218/options/169': $localize`on longitudinal semi-elliptic leaf springs`,
  'specs/attrs/15/217/218/options/170': $localize`on longitudinal cantilever leaf springs`,
  'specs/attrs/15/217/218/options/171': $localize`on the longitudinal balancing leaf springs`,
  'specs/attrs/15/217/218/options/172': $localize`pneumatic`,
  'specs/attrs/15/217/218/options/173': $localize`hydropneumatic`,
  'specs/attrs/15/217/218/options/174': $localize`torsion`,
  'specs/attrs/15/217/218/options/175': $localize`torsion bar with the push rod`,
  'specs/attrs/15/217/218/options/176': $localize`torsion bar with the pull rod`,
  'specs/attrs/15/217/218/options/177': $localize`rubber-elastic elements`,
  'specs/attrs/15/217/218/options/179': $localize`none`,
  'specs/attrs/15/217/219/options/180': $localize`dependent`,
  'specs/attrs/15/217/219/options/181': $localize`dependent linkage-less`,
  'specs/attrs/15/217/219/options/182': $localize`dependent to the trailing arms`,
  'specs/attrs/15/217/219/options/183': $localize`dependent with trailing arms with Watt's mechanism`,
  'specs/attrs/15/217/219/options/184': $localize`dependent with trailing arms with Scott-Russell mechanism`,
  'specs/attrs/15/217/219/options/185': $localize`dependent with trailing arms with a Panhard rod`,
  'specs/attrs/15/217/219/options/186': $localize`dependent to the hard pipe or drawbar (Torque-tube or Torque-Rod)`,
  'specs/attrs/15/217/219/options/187': $localize`dependent with hard pipe or drawbar (Torque-tube or Torque-Rod) with Watt's mechanism`,
  'specs/attrs/15/217/219/options/188': $localize`dependent with hard pipe or drawbar (Torque-tube or Torque-Rod) with Scott-Russell mechanism`,
  'specs/attrs/15/217/219/options/189': $localize`dependent with hard pipe or drawbar (Torque-tube or Torque-Rod) with a Panhard rod`,
  'specs/attrs/15/217/219/options/190': $localize`independent`,
  'specs/attrs/15/217/219/options/191': $localize`independent with swinging half-axles`,
  'specs/attrs/15/217/219/options/192': $localize`independent trailing arm`,
  'specs/attrs/15/217/219/options/193': $localize`independent suspension Dubonnet`,
  'specs/attrs/15/217/219/options/194': $localize`independent on oblique levers`,
  'specs/attrs/15/217/219/options/195': $localize`independent double wishbone`,
  'specs/attrs/15/217/219/options/196': $localize`independent multi-link`,
  'specs/attrs/15/217/219/options/197': $localize`independent candle`,
  'specs/attrs/15/217/219/options/198': $localize`independent candle sweep (McPherson)`,
  'specs/attrs/15/217/219/options/199': $localize`independent candle sweep (McPherson) on longitudinal and transverse arms`,
  'specs/attrs/15/217/219/options/200': $localize`semidependent`,
  'specs/attrs/15/217/219/options/201': $localize`semidependent De Dion`,
  'specs/attrs/15/217/219/options/202': $localize`semidependent torsion-lever`,
  'specs/attrs/15/217/219/options/203': $localize`semidependent torsion-lever with Watt's mechanism`,
  'specs/attrs/15/217/219/options/204': $localize`semidependent torsion-lever with Scott-Russell mechanism`,
  'specs/attrs/15/217/219/options/205': $localize`semidependent torsion-lever a Panhard rod`,
  'specs/attrs/15/217/220/223/options/206': $localize`unilateral`,
  'specs/attrs/15/217/220/223/options/207': $localize`double-sided`,
  'specs/attrs/15/217/220/224/options/208': $localize`friction (mechanical)`,
  'specs/attrs/15/217/220/224/options/209': $localize`hydraulic`,
  'specs/attrs/15/217/220/224/options/210': $localize`hydraulic arm`,
  'specs/attrs/15/217/220/224/options/211': $localize`hydraulic twin-tube`,
  'specs/attrs/15/217/220/224/options/212': $localize`hydraulic single tube`,
  'specs/attrs/15/217/220/224/options/213': $localize`oleo pneumatic`,
  'specs/attrs/16/66/options/11': $localize`at left`,
  'specs/attrs/16/66/options/12': $localize`at right`,
  'specs/attrs/16/66/options/13': $localize`at center`,
  'specs/attrs/16/204/options/85': $localize`bearing body`,
  'specs/attrs/16/204/options/86': $localize`frame`,
  'specs/attrs/22/19/20/options/1': $localize`at front`,
  'specs/attrs/22/19/20/options/2': $localize`at rear`,
  'specs/attrs/22/19/20/options/3': $localize`at center`,
  'specs/attrs/22/19/21/options/4': $localize`longitudinally`,
  'specs/attrs/22/19/21/options/5': $localize`transversely`,
  'specs/attrs/22/23/options/6': $localize`injector`,
  'specs/attrs/22/23/options/24': $localize`carburetor`,
  'specs/attrs/22/23/options/25': $localize`2 carburetors`,
  'specs/attrs/22/23/options/26': $localize`4 carburetors`,
  'specs/attrs/22/23/options/27': $localize`6 carburetors`,
  'specs/attrs/22/23/options/55': $localize`3 carburetors`,
  'specs/attrs/22/24/26/options/7': $localize`:@@cylinders-layout-l:L`,
  'specs/attrs/22/24/26/options/8': $localize`:@@cylinders-layout-v:V`,
  'specs/attrs/22/24/26/options/9': $localize`:@@cylinders-layout-w:W`,
  'specs/attrs/22/24/26/options/10': $localize`:@@cylinders-layout-o:O`,
  'specs/attrs/22/24/26/options/101': $localize`:@@cylinders-layout-u:U`,
  'specs/attrs/22/24/26/options/x': $localize`:@@cylinders-layout-x:X`,
  'specs/attrs/22/98/options/28': $localize`petrol`,
  'specs/attrs/22/98/options/29': $localize`hydrogen`,
  'specs/attrs/22/98/options/30': $localize`bioethanol`,
  'specs/attrs/22/98/options/31': $localize`electricity`,
  'specs/attrs/22/98/options/32': $localize`gas`,
  'specs/attrs/22/98/options/33': $localize`diesel`,
  'specs/attrs/22/98/options/34': $localize`CNG`,
  'specs/attrs/22/98/options/35': $localize`LPG`,
  'specs/attrs/22/98/options/36': $localize`A-66`,
  'specs/attrs/22/98/options/37': $localize`A-70`,
  'specs/attrs/22/98/options/38': $localize`A-72`,
  'specs/attrs/22/98/options/39': $localize`A-76`,
  'specs/attrs/22/98/options/40': $localize`A-78`,
  'specs/attrs/22/98/options/41': $localize`A-80`,
  'specs/attrs/22/98/options/42': $localize`AI-92`,
  'specs/attrs/22/98/options/43': $localize`AI-93`,
  'specs/attrs/22/98/options/44': $localize`AI-95`,
  'specs/attrs/22/98/options/45': $localize`AI-98`,
  'specs/attrs/22/98/options/84': $localize`Flex-fuel`,
  'specs/attrs/22/99/options/46': $localize`none`,
  'specs/attrs/22/99/options/47': $localize`yes`,
  'specs/attrs/22/99/options/48': $localize`×2`,
  'specs/attrs/22/99/options/49': $localize`×4`,
  'specs/attrs/22/99/options/54': $localize`×3`,
  'specs/attrs/22/156/options/68': $localize`cast iron`,
  'specs/attrs/22/156/options/69': $localize`aluminium alloy`,
  'specs/attrs/22/156/options/70': $localize`steel`,
  'specs/attrs/22/156/options/83': $localize`magnesium alloy`,
  'specs/attrs/22/179/options/81': $localize`air`,
  'specs/attrs/22/179/options/82': $localize`liquid`,
  'specs/attrs/22/179/options/liquid-air': $localize`liquid-air`,
  'specs/attrs/22/206/options/88': $localize`The camshaft in the engine block`,
  'specs/attrs/22/206/options/89': $localize`The camshaft in the cylinder head`,
  'specs/attrs/22/206/options/90': $localize`Десмодромный ГРМ`,
  'specs/attrs/22/206/options/91': $localize`Timing without camshaft`,
  'specs/attrs/22/206/options/92': $localize`avalvular`,
  'specs/attrs/22/206/options/93': $localize`bottom valve`,
  'specs/attrs/22/206/options/94': $localize`With a mixed arrangement of valves`,
  'specs/attrs/22/206/options/95': $localize`OHV`,
  'specs/attrs/22/206/options/96': $localize`SOHC`,
  'specs/attrs/22/206/options/97': $localize`SOHC with variable valve timing`,
  'specs/attrs/22/206/options/98': $localize`DOHC`,
  'specs/attrs/22/206/options/99': $localize`DOHC with variable valve timing`,
  'specs/attrs/22/206/options/100': $localize`OHV with variable valve timing`,
  'specs/attrs/22/207/options/102': $localize`Steam`,
  'specs/attrs/22/207/options/103': $localize`Internal combustion`,
  'specs/attrs/22/207/options/104': $localize`Electric`,
  'specs/attrs/22/207/options/105': $localize`Piston`,
  'specs/attrs/22/207/options/106': $localize`Rotary-piston`,
  'specs/attrs/22/207/options/107': $localize`Turbine`,
  'specs/attrs/40/41/options/14': $localize`front-wheel drive`,
  'specs/attrs/40/41/options/15': $localize`rear-wheel drive`,
  'specs/attrs/40/41/options/16': $localize`all-wheel drive`,
  'specs/attrs/40/41/options/17': $localize`four-wheel drive with switchable rear`,
  'specs/attrs/40/41/options/18': $localize`four-wheel drive with switchable front`,
  'specs/attrs/40/41/options/19': $localize`ermanent all-wheel drive`,
  'specs/attrs/40/41/options/56': $localize`front-wheel drive on one wheel`,
  'specs/attrs/40/41/options/57': $localize`rear-wheel drive on one wheel`,
  'specs/attrs/40/42/43/options/20': $localize`Manual`,
  'specs/attrs/40/42/43/options/21': $localize`Automatic`,
  'specs/attrs/40/42/43/options/22': $localize`CVT`,
  'specs/attrs/40/42/43/options/23': $localize`Semi-automatic`,
  'specs/attrs/40/42/43/options/50': $localize`robotised`,
  'specs/attrs/40/42/43/options/51': $localize`DSG`,
  'specs/attrs/40/42/43/options/52': $localize`DCT`,
  'specs/attrs/40/42/43/options/87': $localize`sequential`,
  'specs/attrs/74/142/144/options/58': $localize`drum`,
  'specs/attrs/74/142/144/options/59': $localize`disc`,
  'specs/attrs/74/142/150/options/62': $localize`metal`,
  'specs/attrs/74/142/150/options/63': $localize`carbon`,
  'specs/attrs/74/142/150/options/64': $localize`ceramics`,
  'specs/attrs/74/143/145/options/60': $localize`drum`,
  'specs/attrs/74/143/145/options/61': $localize`disc`,
  'specs/attrs/74/143/151/options/65': $localize`metal`,
  'specs/attrs/74/143/151/options/66': $localize`carbon`,
  'specs/attrs/74/143/151/options/67': $localize`ceramics`,
  'specs/attrs/84/165/options/78': $localize`steel`,
  'specs/attrs/84/165/options/79': $localize`aluminium alloy (molding)`,
  'specs/attrs/84/165/options/80': $localize`aluminium alloy (forging)`,
  'specs/attrs/157/options/71': $localize`Euro 1`,
  'specs/attrs/157/options/72': $localize`Euro 2`,
  'specs/attrs/157/options/73': $localize`Euro 3`,
  'specs/attrs/157/options/74': $localize`Euro 4`,
  'specs/attrs/157/options/75': $localize`Euro 5`,
  'specs/attrs/157/options/76': $localize`Euro 5+`,
  'specs/attrs/157/options/77': $localize`Euro 6`,
  'specs/attrs/157/options/euro-5a': $localize`Euro 5a`,
  'specs/attrs/157/options/euro-5b': $localize`Euro 5b`,
  'specs/attrs/157/options/euro-6d': $localize`Euro 6d`,
  'specs/attrs/engine/turbo/options/x6': $localize`×6`,
};

const mostsRatings: {
  [key: string]: string;
} = {
  'most/air': $localize`air`,
  'most/antiair': $localize`anti air`,
  'most/big-engine': $localize`big engine`,
  'most/bigbrakes': $localize`big brakes`,
  'most/bigclearance': $localize`big clearance`,
  'most/bigwheel': $localize`big wheel`,
  'most/clenaly': $localize`ecological cleanly`,
  'most/dirty': $localize`ecological dirty`,
  'most/dynamic': $localize`dynamic`,
  'most/economical': $localize`economical`,
  'most/fastest': $localize`fastest`,
  'most/gluttonous': $localize`gluttonous`,
  'most/heavy': $localize`heavy`,
  'most/highest': $localize`highest`,
  'most/lightest': $localize`lightest`,
  'most/longest': $localize`longest`,
  'most/lowest': $localize`lowest`,
  'most/mighty': $localize`mighty`,
  'most/narrow': $localize`narrow`,
  'most/nimblest': $localize`nimblest`,
  'most/shortest': $localize`shortest`,
  'most/slowest': $localize`slowest`,
  'most/small-engine': $localize`small engine`,
  'most/smallbrakes': $localize`small brakes`,
  'most/smallclearance': $localize`small clearance`,
  'most/smallwheel': $localize`small wheel`,
  'most/static': $localize`static`,
  'most/weak': $localize`weak`,
  'most/widest': $localize`widest`,
};

const mostsRatingsParam: {
  [key: string]: string;
} = {
  'most/air/param': $localize`drag coefficients`,
  'most/antiair/param': $localize`drag coefficients`,
  'most/big-engine/param': $localize`engine volume`,
  'most/bigbrakes/param': $localize`brakes size`,
  'most/bigclearance/param': $localize`clearance`,
  'most/bigwheel/param': $localize`wheel size`,
  'most/clenaly/param': $localize`emission`,
  'most/dirty/param': $localize`emission`,
  'most/dynamic/param': $localize`acceleration`,
  'most/economical/param': $localize`fuel consumption in combined cycle`,
  'most/fastest/param': $localize`max speed`,
  'most/gluttonous/param': $localize`fuel consumption in combined cycle`,
  'most/heavy/param': $localize`equipped weight`,
  'most/highest/param': $localize`height`,
  'most/lightest/param': $localize`equipped weight`,
  'most/longest/param': $localize`length`,
  'most/lowest/param': $localize`height`,
  'most/mighty/param': $localize`engine power`,
  'most/narrow/param': $localize`width`,
  'most/nimblest/param': $localize`turning circle`,
  'most/shortest/param': $localize`length`,
  'most/slowest/param': $localize`max speed`,
  'most/small-engine/param': $localize`engine volume`,
  'most/smallbrakes/param': $localize`brakes size`,
  'most/smallclearance/param': $localize`clearance`,
  'most/smallwheel/param': $localize`wheel size`,
  'most/static/param': $localize`acceleration`,
  'most/weak/param': $localize`engine power`,
  'most/widest/param': $localize`width`,
};

const mostsPeriods: {
  [key: string]: string;
} = {
  'mosts/period/1920-29': $localize`1920-29th`,
  'mosts/period/1930-39': $localize`1930-39th`,
  'mosts/period/1940-49': $localize`1940-49th`,
  'mosts/period/1950-59': $localize`1950-59th`,
  'mosts/period/1960-69': $localize`1960-69th`,
  'mosts/period/1970-79': $localize`1970-79th`,
  'mosts/period/1980-89': $localize`1980-89th`,
  'mosts/period/1990-99': $localize`1990-99th`,
  'mosts/period/2000-09': $localize`2000-09th`,
  'mosts/period/2010-17': $localize`2010-17th`,
  'mosts/period/all-time': $localize`all time`,
  'mosts/period/before1920': $localize`before 1920`,
  'mosts/period/present': $localize`present time`,
};

const catalogueSection: {
  [key: string]: string;
} = {
  'catalogue/section/buses': $localize`Buses`,
  'catalogue/section/engines': $localize`Engines`,
  'catalogue/section/moto': $localize`Moto`,
  'catalogue/section/tractors': $localize`Tractors`,
  'catalogue/section/trucks': $localize`Trucks`,
  Other: $localize`Other`,
};

export function getItemTypeTranslation(id: number, type: string): string {
  const translation = translations[id];
  if (!translation) {
    return '-';
  }
  switch (type) {
    case 'add-sub-item':
    case 'name':
    case 'name-plural':
    case 'new-item':
      return translation[type];
  }
  return '-';
}

export function getUnitAbbrTranslation(id: string): string {
  return units[id] && units[id].abbr ? units[id].abbr : id;
}

export function getUnitNameTranslation(id: string): string {
  return units[id] && units[id].name ? units[id].name : id;
}

export function getForumsThemeTranslation(id: string): string {
  return themes[id] ? themes[id] : id;
}

export function getForumsThemeDescriptionTranslation(id: string): string {
  return themesDescription[id] ? themesDescription[id] : id;
}

export function getPerspectiveTranslation(id: string): string {
  return prespectives[id] ? prespectives[id] : id;
}

export function getVehicleTypeTranslation(id: string): string {
  return vehicleTypes[id] ? vehicleTypes[id] : id;
}

export function getVehicleTypeRpTranslation(id: string): string {
  return vehicleTypesRp[id] ? vehicleTypesRp[id] : id;
}

export function getAttrsTranslation(id: string): string {
  return attrs[id] ? attrs[id] : id;
}

export function getAttrListOptionsTranslation(id: string): string {
  return attrListOptions[id] ? attrListOptions[id] : id;
}

export function getAttrDescriptionTranslation(id: string): string {
  return attrDescriptions[id] ? attrDescriptions[id] : id;
}

export function getMostsRatingsTranslation(id: string): string {
  return mostsRatings[id] ? mostsRatings[id] : id;
}

export function getMostsRatingParamsTranslation(id: string): string {
  return mostsRatingsParam[id] ? mostsRatingsParam[id] : id;
}

export function getMostsPeriodsTranslation(id: string): string {
  return mostsPeriods[id] ? mostsPeriods[id] : id;
}

export function getCatalogueSectionsTranslation(id: string): string {
  return catalogueSection[id] ? catalogueSection[id] : id;
}

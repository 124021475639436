<div class="modal-header">
  <h3 class="modal-title" i18n>Online</h3>
  <button class="btn-close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  @for (user of users$ | async; track user.id) {
    <app-user [user]="user"></app-user>
  }
</div>
<div class="modal-footer">
  <button type="button" (click)="load()" class="btn btn-secondary" i18n>Refresh</button>
  <button type="button" (click)="activeModal.dismiss()" class="btn btn-secondary" i18n>Close</button>
</div>
